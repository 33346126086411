// Imports ---------------------------------------------- /
@import "../../styles/mixins";

// Base ------------------------------------------------- /
.base {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    91.28deg,
    rgba(8, 10, 18, 0.252) 29.29%,
    rgba(61, 64, 86, 0.288) 82.77%
  );
  border-radius: 60px;
  box-shadow: 0px 4px 35px rgba(21, 21, 21, 0.25);
  height: fit-content;
  width: 100%;
}

.wrapper {
  // border: 1px dashed yellow;
  display: flex;
  gap: 24px;
  height: 100%;
  padding: 32px;
}

.left {
  // border: 1px dashed yellow;
  backdrop-filter: blur(25px);
  background: linear-gradient(
    183.01deg,
    rgba(148, 175, 247, 0.07) 6.42%,
    rgba(148, 175, 247, 0) 131.43%
  );
  border-radius: 40px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  padding: 32px;
  width: 100%;
}

.leftHeading {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  margin-bottom: 32px;
  text-transform: uppercase;
}

.graph {
  height: 80px;
  width: 100%;
}

.leftNumbers {
  display: flex;
}

.leftPrice {
  color: #ffffff;
  font-size: 30px;
  font-weight: 500;
  margin-right: 12px;

  & span {
    color: #ffb444;
  }
}

.leftPercent {
  color: #86ffbe;
  font-size: 9px;
  font-weight: 700;
  margin-top: 8px;
}

.right {
  // border: 1px dashed yellow;
  width: 100%;
  // padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.row {
  display: flex;
  justify-content: space-between;
}

.badge {
  // border: 1px dashed yellow;
  position: relative;

  & > svg {
    position: absolute;
    right: 4px;
    top: -4px;
    z-index: 10;
  }
}

.badgeWrapper {
  @include rounded-hex($rotated: true);
  align-items: center;
  background: #4e5375;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.1);
  display: flex;
  height: 100px;
  justify-content: center;
  width: 100px;
}

.badgeWrapper2 {
  @include rounded-hex($rotated: true);
  align-items: center;
  background: #222738;
  display: flex;
  flex-direction: column;
  height: calc(100% - 8px);
  justify-content: center;
  width: calc(100% - 8px);

  & > span:nth-child(1) {
    color: #ffffff;
    font-size: 18px;
    font-weight: 500;
  }

  & > span:nth-child(2) {
    color: #d7ff83;
    font-size: 8px;
    font-weight: 600;
  }

  & > span:nth-child(3) {
    color: #72799b;
    font-size: 8px;
    font-weight: 400;
  }
}

.base {
  // border: 1px dashed red;
  display: flex;
  height: 64px;
  justify-content: center;
  margin: 48px 0;
  overflow: hidden;
  width: 100%;
}

.wrapper {
  // border: 1px dashed red;
  align-items: center;
  display: flex;
  margin: 0 var(--global-margins);
  width: var(--global-width);
  justify-content: space-between;
}

.brand {
  // border: 1px dashed red;
  height: 54px;
}

.logo {
  height: 100%;
  user-select: none;
}

.buttons {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;
  gap: 48px;
  height: 100%;
}

.button {
  align-items: center;
  background: linear-gradient(
    180.3deg,
    #82b4ff -376.28%,
    rgba(130, 180, 255, 0) 99.91%
  );
  border-radius: 25px;
  border: 1.5px solid #82b4ff;
  box-shadow: inset 0px 4px 25px rgba(0, 0, 0, 0.25);
  color: var(--gray1);
  display: flex;
  font-size: 12px;
  font-weight: 600;
  height: 100%;
  padding: 0 48px;
  text-transform: uppercase;
}

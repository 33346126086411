// Imports ---------------------------------------------- /
@import "../../styles/mixins";

// Base ------------------------------------------------- /
.base {
  // border: 1px dashed yellow;
  align-items: flex-end;
  display: flex;
  // height: 320px;
  position: relative;
  width: 100%;
}

.subbase1 {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    157.38deg,
    rgba(130, 180, 255, 0) 117.61%,
    rgba(130, 180, 255, 0.05) 165.96%,
    rgba(130, 180, 255, 0) 213.31%
  );
  border-radius: 64px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
  height: calc(100% - 32px);
  width: 100%;
  z-index: 3;
}

.subbase2 {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    135.35deg,
    #202335 -27.29%,
    rgba(32, 35, 53, 0) 114.06%
  );
  border-radius: 64px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  height: calc(100% - 16px);
  left: 32px;
  opacity: 0.5;
  position: absolute;
  right: 32px;
  z-index: 2;
}

.subbase3 {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    135.35deg,
    #202335 -27.29%,
    rgba(32, 35, 53, 0) 114.06%
  );
  border-radius: 64px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  height: 100%;
  left: 64px;
  opacity: 0.5;
  position: absolute;
  right: 64px;
  z-index: 1;
}

// Repeated for easier read
.subbase1 {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.top {
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 16px;
}

.flex {
  align-items: center;
  display: flex;
}

.heading {
  color: #ffb629;
  font-size: 15px;
  font-weight: 600;
  margin-right: 16px;
}

.top__textMuted {
  color: #72799b;
  font-size: 12px;
  font-weight: 500;
  margin-right: 12px;
}

.top__textWhite {
  color: #ffffff;
  font-weight: 500;
  font-size: 15px;
}

.top__textYellow {
  color: #ffb629;
  font-weight: 500;
  font-size: 15px;
}

.subheading {
  color: #72799b;
  font-size: 12px;
  font-weight: 500;
}

// Table
.table {
  // border: 1px dashed #ffb629;
  display: flex;
  flex-direction: column;
}

.table__row {
  // border: 1px dashed #ffb629;
  align-items: center;
  color: #72799b;
  display: flex;
  margin-bottom: 32px;
}

.table__row span:nth-child(1) {
  flex: 1.2;
}

.table__row span:nth-child(2) {
  flex: 0.8;
}

.table__row span:nth-child(3) {
  flex: 2;
}

.table__row span:nth-child(4) {
  flex: 2;
}

.table__row span:nth-child(5) {
  flex: 1;
}

.table__row span {
  color: #72799b;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
}

.table__row span:first-child {
  text-align: start;
}

.table__row span:last-child {
  color: #ffffff;
  font-weight: 600;
  text-transform: uppercase;
}

.table__row:first-child span {
  color: #ffffff;
  font-size: 10px;
  font-weight: 600;
  opacity: 0.6;
  text-transform: uppercase;
}

// Last
.claim {
  @include btn-reset;
  color: #82b4ff;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 32px;
  text-transform: uppercase;
}

// Modal -------------------------------------- /
.modal {
  // border: 1px dashed yellow;
  background: linear-gradient(
    184deg,
    rgba(148, 175, 247, 0.12) 3.27%,
    rgba(148, 175, 247, 0) 158.7%
  );
  backdrop-filter: blur(25px);
  border-radius: 40px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.05));
  height: 200px;
  left: 32px;
  overflow-y: auto;
  padding: 24px;
  position: absolute;
  right: 32px;
  top: 104px;
  z-index: 10;
}

.modal__close {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 24px;
}

.modal__heading {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}

.modal__body {
  color: #72799b;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 32px;
}

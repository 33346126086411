// Imports ---------------------------------------------- /
@import "../../styles/mixins";

// Base ------------------------------------------------- /
.base {
  // border: 1px dashed yellow;
  border-radius: 60px;
  position: relative;
  width: 100%;
}

.wrapper {
  // border: 1px dashed yellow;
  display: flex;
  gap: 24px;
  height: 100%;
  padding: 32px;
  flex-direction: column;
}

.top {
  // border: 1px dashed yellow;
  display: flex;
  justify-content: space-between;
}

.topLeft {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;

  & > span {
    color: #ffffff;
    font-size: 15px;
    font-weight: 600;
    margin-right: 16px;
  }
}

.topRight {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;

  & > p {
    color: #72799b;
    font-size: 12px;
    font-weight: 500;

    & > span {
      font-weight: 600;
    }
  }

  & > button:nth-of-type(1) {
    @include btn-reset;
    // border: 1px dashed yellow;
    color: #82b4ff;
    font-size: 12px;
    font-weight: 600;
    margin-left: 16px;
  }

  & > button:nth-of-type(2) {
    @include btn-reset;
    margin-left: 24px;
  }
}

.carousel__container {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.carousel__wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: space-between;
  width: 100%;

  & > button {
    @include btn-reset;
    align-items: center;
    border-radius: 10px;
    border: 1.5px solid #82b4ff4d;
    display: flex;
    height: 40px;
    justify-content: center;
    min-width: 40px;
  }
}

.carousel__name {
  // border: 1px dashed yellow;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  margin-top: 12px;
}

.carousel {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  min-width: 0;
  position: relative;
  width: 100%;
}

.carousel {
  & img {
    border-radius: 20px;
    height: 100%;
    transition: transform 200ms, left 200ms;
  }
}

.carousel > [data-position="previous"] {
  left: 0;
  position: absolute;
  transform: perspective(320px) rotateY(45deg) scale(0.8);
}

.carousel > [data-position="current"] {
  left: calc(50% - 75px);
  position: absolute;
  transform: none;
}

.carousel > [data-position="next"] {
  left: calc(100% - 150px);
  position: absolute;
  transform: perspective(320px) rotateY(-45deg) scale(0.8);
}

// Modal -------------------------------------- /
.modal {
  // border: 1px dashed yellow;
  background: linear-gradient(
    184deg,
    rgba(148, 175, 247, 0.12) 3.27%,
    rgba(148, 175, 247, 0) 158.7%
  );
  backdrop-filter: blur(25px);
  border-radius: 40px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.05));
  height: 200px;
  left: 32px;
  overflow-y: auto;
  padding: 24px;
  position: absolute;
  right: 32px;
  top: 70px;
  z-index: 10;
}

.modal__close {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 24px;
}

.modal__heading {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}

.modal__body {
  color: #72799b;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 32px;
}

// Imports ---------------------------------------------- /
@import "../../styles/mixins";

// Base ------------------------------------------------- /
.base {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    91.28deg,
    rgba(8, 10, 18, 0.252) 29.29%,
    rgba(61, 64, 86, 0.288) 82.77%
  );
  border-radius: 60px;
  box-shadow: 0px 4px 35px rgba(21, 21, 21, 0.25);

  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  padding: 48px;
}

.top {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
}

.titles {
  display: flex;
  gap: 48px;
}

.titles button {
  @include btn-reset;
  color: #72799b;
  font-size: 15px;
  font-weight: 700;
  white-space: nowrap;
}

.titles button:first-child {
  color: #ffb629;
}

// Graph
.graph {
  height: 240px;
}

.graph__legend {
  // border: 1px dashed yellow;
  align-items: center;
  color: #72799b;
  display: flex;
  font-family: var(--font-sans);
  font-size: 10px;
  font-weight: 700;
  justify-content: center;
  margin-top: 48px;
}

.graph__legend div {
  // border: 1px dashed yellow;
  background: linear-gradient(270deg, #7795f9 0%, #a392db 100%);
  border-radius: 10px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  height: 5px;
  margin-right: 10px;
  width: 54px;
}

.center {
  // border: 1px dashed yellow;
  display: flex;
  justify-content: center;
}

.center__wrapper {
  // border: 1px dashed yellow;
  display: flex;
  gap: 4vw;
  margin: 0 var(--global-margins);
  width: var(--global-width);
}

.main {
  // border: 1px dashed pink;
  display: flex;
  flex-direction: column;
  gap: 64px;
  width: 100%;
}

.main__flex {
  display: flex;
  gap: 32px;
  height: 100%;
}

.base {
  // border: 1px dashed yellow;
  // display: flex;
  // height: 64px;
  // justify-content: center;
  // margin-top: 48px;
  // width: 100%;
}

.heading {
  color: #44485f;
  font-size: 10px;
  font-weight: 700;
  margin-bottom: 48px;
  text-transform: uppercase;
}

.link {
  // border: 1px dashed yellow;
  align-items: center;
  color: #636886;
  display: flex;
  margin-bottom: 48px;
  user-select: none;
}

.link.active {
  // border: 1px dashed yellow;
  color: #ffb629;
}

.link svg {
  height: 22px;
  margin-right: 24px;
  width: 22px;
}

.link span {
  font-size: 10px;
  font-weight: 700;
  // text-transform: uppercase;
  text-transform: capitalize;
  white-space: nowrap;
}

.link.active div {
  // border: 1px dashed yellow;
  background-color: #ffb629;
  border-radius: 14px;
  height: 44px;
  left: -6px;
  pointer-events: none;
  position: absolute;
  width: 12px;
}

// Imports ---------------------------------------------- /
@import "../../styles/mixins";

// Base ------------------------------------------------- /
.base {
  // border: 1px dashed yellow;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination {
  // border: 1px dashed yellow;
  display: flex;
  height: 44px;
}

.button {
  color: #72799b;
  &:hover svg {
    color: #ffffff;
  }
}

.numbers {
  margin: 0 48px;
  display: flex;
}

.number {
  // border: 1px dashed yellow;
  align-items: center;
  border-radius: 10px;
  color: #72799b;
  cursor: pointer;
  display: flex;
  font-size: 13px;
  font-weight: 600;
  height: 44px;
  justify-content: center;
  width: 44px;

  &:hover {
    color: #ffffff;
  }
}

.number.active {
  background: linear-gradient(
    180.3deg,
    #82b4ff -376.28%,
    rgba(130, 180, 255, 0) 99.91%
  );
  border: 1.5px solid #82b4ff;
  border: 1px solid #82b4ff;
  box-shadow: inset 0px 4px 25px rgba(0, 0, 0, 0.25);
  box-sizing: border-box;
  color: #ffffff;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.1));
}

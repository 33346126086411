// Imports ---------------------------------------------- /
@import "../../styles/mixins";

// Base ------------------------------------------------- /
.base {
  // border: 1px dashed yellow;
  display: flex;
  flex-direction: column;
  margin-top: 64px;
  width: 100%;
}

.wrapper {
  display: flex;
  flex-direction: column;
  // padding: 48px;
}

.headers {
  display: flex;
  margin-bottom: 24px;
}

.headers span {
  align-items: center;
  color: #ffffff;
  display: flex;
  flex: 3;
  font-size: 10px;
  font-weight: 700;
  justify-content: center;
  line-height: 1.5;
  opacity: 0.6;
  text-align: center;
  text-transform: uppercase;
}

.headers span:nth-child(1) {
  flex: 2;
}

.headers span:nth-last-child(2) {
  flex: 2;
}

.headers span:last-child {
  flex: 2;
}

.container {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    91.28deg,
    rgba(8, 10, 18, 0.252) 29.29%,
    rgba(49, 51, 69, 0.2205) 82.77%
  );
  border-radius: 30px;
  box-shadow: 0px 4px 35px rgba(21, 21, 21, 0.25);
  display: flex;
  height: 120px;
  margin-bottom: 32px;
}

.container > div {
  // border: 1px dashed yellow;
  align-items: center;
  color: #ffffff;
  display: flex;
  flex: 3;
  justify-content: center;

  &:nth-child(1) {
    flex: 2;
  }

  &:nth-last-child(2) {
    flex: 2;
  }

  &:last-child {
    flex: 2;
  }
}

.container > div:nth-child(1) {
  display: flex;
  flex-direction: column;

  & svg {
    height: 36px;
    margin-bottom: 12px;
    width: 36px;
  }

  & p {
    color: #ffffff;
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.container > div:nth-child(2) {
  display: flex;
  flex-direction: column;

  & div:last-child {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 72%;
  }

  & div > span:first-child {
    font-size: 12px;
    font-weight: 500;
  }

  & div > span:last-child {
    font-size: 9px;
    font-weight: 700;
  }
}

.container > div:nth-child(3) span,
.container > div:nth-child(4) span,
.container > div:nth-child(5) span,
.container > div:nth-child(6) span {
  font-weight: 500;
}

.container > div:nth-child(7) span {
  font-weight: 700;
}

.textGreen {
  color: #86ffbe;
}

.textRed {
  color: #ff7a72;
}

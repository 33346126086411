// Imports ---------------------------------------------- /
@import "../../styles/mixins";

// Base ------------------------------------------------- /
.base {
  // border: 1px dashed yellow;
  align-items: flex-end;
  display: flex;
  // height: 320px;
  position: relative;
  width: 100%;
}

.subbase1 {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    157.38deg,
    rgba(130, 180, 255, 0) 117.61%,
    rgba(130, 180, 255, 0.05) 165.96%,
    rgba(130, 180, 255, 0) 213.31%
  );
  border-radius: 64px;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.25);
  height: calc(100% - 32px);
  position: relative;
  width: 100%;
  z-index: 3;
}

.subbase2 {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    135.35deg,
    #202335 -27.29%,
    rgba(32, 35, 53, 0) 114.06%
  );
  border-radius: 64px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  height: calc(100% - 16px);
  left: 32px;
  opacity: 0.5;
  position: absolute;
  right: 32px;
  z-index: 2;
}

.subbase3 {
  // border: 1px dashed yellow;
  backdrop-filter: blur(65px);
  background: linear-gradient(
    135.35deg,
    #202335 -27.29%,
    rgba(32, 35, 53, 0) 114.06%
  );
  border-radius: 64px;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.05);
  height: 100%;
  left: 64px;
  opacity: 0.5;
  position: absolute;
  right: 64px;
  z-index: 1;
}

// Repeated for easier read
.subbase1 {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.top {
  align-items: center;
  display: flex;
  font-size: 15px;
  font-weight: 600;
  justify-content: space-between;
  margin-bottom: 16px;
}

.flex {
  align-items: center;
  display: flex;
}

.heading {
  color: #ffffff;
  font-size: 15px;
  font-weight: 600;
  margin-right: 16px;
}

.subheading {
  color: #72799b;
  font-size: 12px;
  font-weight: 500;
}

.slider {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
}

.slider__text {
  color: #ffffff;
  font-size: 12px;
  margin-bottom: 16px;
}

.slider__text span {
  font-size: 14px;
  font-weight: 600;
}

.slider__label {
  align-items: center;
  color: #72799b;
  display: flex;
  font-size: 12px;
  font-weight: 500;
  height: 24px;
  justify-content: space-between;
  margin-top: 4px;
  user-select: none;
  width: 100%;
}

.slider__labelActive {
  color: #ffffff;
  font-size: 16px;
}

// Selection
.selection {
  margin-bottom: 64px;
}

.selection__label {
  color: #72799b;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}

.selection__buttons {
  display: flex;
  gap: 24px;
}

.selection__button {
  @include btn-reset;
  align-items: center;
  backdrop-filter: blur(25px);
  border-radius: 25px;
  border: 1.5px solid #82b4ff;
  color: #ffffff;
  display: flex;
  font-size: 10px;
  font-weight: 600;
  height: 54px;
  justify-content: space-between;
  padding: 0 16px;
  width: 100%;
}

.selection__button div {
  align-items: center;
  display: flex;
}

.selection__button svg {
  margin-right: 8px;
}

.selection__button span {
  white-space: nowrap;
}

.selection__buttonNumber {
  color: #ffb629;
  font-weight: 600;
  font-size: 12px;
}

.mint {
  @include btn-reset;
  @include rounded-hex($rotated: true);
  align-items: center;
  background-color: #1f2133;
  bottom: -50px;
  color: #72799b;
  display: flex;
  justify-content: center;
  left: calc(50% - 50px);
  min-height: 100px;
  min-width: 100px;
  position: absolute;
  text-transform: uppercase;
  width: 100px;

  &:hover .mint__wrapper {
    background-color: #ffc164;
  }
}

.mint__wrapper {
  @include rounded-hex($rotated: true);
  align-items: center;
  background-color: #72799b;
  display: flex;
  height: 80px;
  justify-content: center;
  width: 80px;
}

.mint__wrapper2 {
  @include rounded-hex($rotated: true);
  align-items: center;
  background-color: #24283c;
  display: flex;
  height: 78px;
  justify-content: center;
  width: 78px;
}

.mint span {
  font-size: 12px;
  font-weight: 600;
}

// Modal -------------------------------------- /
.modal {
  // border: 1px dashed yellow;
  background: linear-gradient(
    184deg,
    rgba(148, 175, 247, 0.12) 3.27%,
    rgba(148, 175, 247, 0) 158.7%
  );
  backdrop-filter: blur(25px);
  border-radius: 40px;
  filter: drop-shadow(0px 4px 25px rgba(0, 0, 0, 0.05));
  height: 200px;
  left: 32px;
  overflow-y: auto;
  padding: 24px;
  position: absolute;
  right: 32px;
  top: 104px;
  z-index: 10;
}

.modal__close {
  // border: 1px dashed yellow;
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 24px;
  top: 24px;
}

.modal__heading {
  color: #ffffff;
  font-size: 12px;
  font-weight: 500;
  margin-bottom: 12px;
}

.modal__body {
  color: #72799b;
  font-size: 12px;
  font-weight: 400;
  line-height: 2;
  margin-bottom: 32px;
}

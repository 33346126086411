// ====================================================== /
// Global
// ====================================================== /

// Tailwind  -------------------------------------------- /
@tailwind base;
@tailwind components;
@tailwind utilities;

// Imports ---------------------------------------------- /
@import "normalize";
@import "theme";
@import "colors";

// Root ------------------------------------------------- /
:root {
  @include colors;
  @include mauveA;
  @include crimsonA;

  // Layout
  --global-margins: 20px;
  --global-width: 1320px;

  // Font
  --font-sans: "Poppins", -apple-system, sans-serif;
  --font-mono: "JetBrains Mono", monospace;

  // Time
  --transition-time: 200ms;

  // UI
  --active-opacity: 0.64;

  // Theme
  --link-anchor-color: var(--crimson11);
}

.recharts-text {
  font-family: var(--font-sans);
  font-size: 10px;
  font-weight: 700;
}

.recharts-legend-item-text {
  color: #72799b;
  font-family: var(--font-sans);
  font-size: 10px;
  font-weight: 700;
}

.recharts-wrapper .recharts-cartesian-grid-horizontal line:nth-last-child(1),
.recharts-wrapper .recharts-cartesian-grid-vertical line:nth-last-child(1),
.recharts-wrapper .recharts-cartesian-grid-vertical line:nth-last-child(2) {
  stroke-opacity: 0;
}

.recharts__barChart .recharts-xAxis .recharts-text {
  fill: #ffffff;
}
